import {type AppError, type Result} from '@packages/utils';
import {
  type UseQueryOptions,
  useQuery,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  useMutation,
} from '@tanstack/react-query';
import {type z} from 'zod';
import {
  type GetCommunityTopicListResponse,
  communityTopicsHeadlineSchema,
  GetCommunityTopicListResponseSchema,
  type UpdateFollowingCommunityTopicResponse,
  UpdateFollowingCommunityTopicResponseSchema,
  type PostCommunityTopicResponse,
  PostCommunityTopicResponseScheme,
  type GetCommunityTopicResponse,
  GetCommunityTopicResponseScheme,
  type PostAlertCommunityTopicResponse,
  PostAlertCommunityTopicResponseScheme,
  GetCommunityDoubtReasonResponseSchema,
  type GetCommunityDoubtReasonResponse,
  type GetCommunityCommentsResponse,
  GetCommunityCommentsResponseSchema,
  type PostCommunityCommentResponse,
  PostCommunityCommentResponseSchema,
  type GetCommunityCommentResponse,
  GetCommunityCommentResponseSchema,
  type UpdateLikedCommunityCommentResponse,
  UpdateLikedCommunityCommentResponseSchema,
  type PostAlertCommunityCommentResponse,
  PostAlertCommunityCommentResponseSchema,
  type PostDoubtCommunityCommentResponse,
  PostDoubtCommunityCommentResponseSchema,
  type GetCommunityTagListResponseType,
  GetCommunityTagListResponseScheme,
} from '../entities/community/schema';
import {defaultClient} from '../../lib/defaultClient';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {createPathWithQuery} from '../../shared/services/createPathWithQuery';
import {filterExistParams} from '../../shared/services/filterExistParams';

type GetCommunityTopicsHeadlineResponse = z.infer<
  typeof communityTopicsHeadlineSchema
>;
type ResultGetCommunityTopicsHeadline = Result<
  GetCommunityTopicsHeadlineResponse,
  AppError
>;

type DefaultArgs = {
  accessToken: string;
  version?: string;
  config?: UseQueryOptions<ResultGetCommunityTopicsHeadline>;
};

export const getCommunityTopicsHeadline = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
}: DefaultArgs): Promise<ResultGetCommunityTopicsHeadline> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.get(
    `/${version}/community-topics/headline`,
    communityTopicsHeadlineSchema,
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useGetCommunityTopicsHeadline = ({
  accessToken,
  version = DEFAULT_API_VERSION,
  config,
}: DefaultArgs) => {
  return useQuery<ResultGetCommunityTopicsHeadline>({
    ...config,
    queryKey: ['getCommunityTopicsHeadline'],
    async queryFn() {
      return getCommunityTopicsHeadline({accessToken, version});
    },
  });
};

type GetCommunityTopicListArgs = {
  accessToken?: string;
  version?: string;
  cursor?: string; // Paginationのカーソル。トピックの最終投稿時刻
  limit?: number; // 取得件数
  backward?: boolean; // カーソルを基準に古い方向に取ってゆくか
  desc?: boolean; // 降順にするか
  followed?: boolean; // フォローしているもののみ返すか
  tagID?: number; // タグID
  keyword?: string; // キーワード検索
  recruitmentIDs?: number[]; // 募集詳細ID(複数指定化), 募集詳細に関連するトピックを返す
};

export const getCommunityTopicList = async ({
  accessToken = '',
  version = DEFAULT_API_VERSION,
  cursor,
  limit,
  backward,
  desc,
  followed,
  tagID,
  keyword,
  recruitmentIDs,
}: GetCommunityTopicListArgs): Promise<
  Result<GetCommunityTopicListResponse>
> => {
  defaultClient.setToken(accessToken);
  const params = filterExistParams({
    cursor,
    limit,
    backward,
    desc,
    followed,
    tagID,
    keyword,
    recruitmentIDs,
  });

  const path = createPathWithQuery(
    `/${version}/community-topics`,
    params,
    'none',
  );
  const result = await defaultClient.get(
    path,
    GetCommunityTopicListResponseSchema,
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UseGetCommunityTopicListParams = GetCommunityTopicListArgs & {
  config?: UseQueryOptions<Result<GetCommunityTopicListResponse>>;
};

export const useGetCommunityTopicList = ({
  accessToken,
  version = DEFAULT_API_VERSION,
  cursor,
  limit,
  backward,
  desc,
  followed,
  tagID,
  keyword,
  recruitmentIDs,
  config,
}: UseGetCommunityTopicListParams) => {
  return useQuery<Result<GetCommunityTopicListResponse>>({
    ...config,
    queryKey: [
      'getCommunityTopicList',
      cursor,
      limit,
      backward,
      desc,
      followed,
      tagID,
      keyword,
      recruitmentIDs,
    ],
    async queryFn() {
      return getCommunityTopicList({
        accessToken,
        version,
        cursor,
        limit,
        backward,
        desc,
        followed,
        tagID,
        keyword,
        recruitmentIDs,
      });
    },
  });
};

type UseInfiniteGetCommunityTopicListParams = GetCommunityTopicListArgs & {
  config?: UseInfiniteQueryOptions<Result<GetCommunityTopicListResponse>>;
};

export const useInfiniteGetCommunityTopicList = ({
  accessToken,
  version = DEFAULT_API_VERSION,
  cursor,
  limit,
  backward,
  desc,
  followed,
  tagID,
  keyword,
  recruitmentIDs,
  config,
}: UseInfiniteGetCommunityTopicListParams) => {
  return useInfiniteQuery({
    queryKey: [
      'getInfiniteCommunityTopicList',
      cursor,
      limit,
      backward,
      desc,
      followed,
      tagID,
      keyword,
      recruitmentIDs,
    ],
    queryFn: async ({pageParam = undefined}) =>
      getCommunityTopicList({
        accessToken,
        version,
        cursor: pageParam,
        limit,
        backward,
        desc,
        followed,
        tagID,
        keyword,
        recruitmentIDs,
      }),
    ...config,
    getNextPageParam: (lastPage) =>
      lastPage.ok ? lastPage.value.previousCursor : undefined, // NextPageParamだが過去方向へのページングなのでpreviousCursorを返す
  });
};

type PostCommunityTopicArgs = {
  accessToken: string;
  version?: string;
  nickname: string;
  title: string;
  body: string;
  tags?: number[];
  hashtags?: string[];
};

export const postCommunityTopic = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  nickname,
  title,
  body,
  tags,
  hashtags,
}: PostCommunityTopicArgs): Promise<Result<PostCommunityTopicResponse>> => {
  defaultClient.setToken(accessToken);

  const params = filterExistParams({
    nickname,
    title,
    body,
    tags,
    hashtags,
  });

  const result = await defaultClient.post(
    `/${version}/community-topics`,
    PostCommunityTopicResponseScheme,
    params,
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UsePostCommunityTopicParams = {
  accessToken: string;
  version?: string;
  onMutateFnc?: () => void;
  onSuccessFnc: () => void;
  onErrorFnc: (error: AppError) => void;
  onSettledFnc?: () => void;
};

export const usePostCommunityTopic = ({
  accessToken,
  version = DEFAULT_API_VERSION,
  onMutateFnc,
  onSuccessFnc,
  onErrorFnc,
  onSettledFnc,
}: UsePostCommunityTopicParams) => {
  return useMutation({
    async mutationFn({
      nickname,
      title,
      body,
      tags,
      hashtags,
    }: Omit<PostCommunityTopicArgs, 'accessToken'>) {
      return postCommunityTopic({
        accessToken,
        version,
        nickname,
        title,
        body,
        tags,
        hashtags,
      });
    },
    onMutate() {
      if (onMutateFnc) {
        onMutateFnc();
      }
    },
    onSuccess() {
      onSuccessFnc();
    },
    onError(error: AppError) {
      onErrorFnc(error);
    },
    onSettled() {
      if (onSettledFnc) {
        onSettledFnc();
      }
    },
  });
};

type GetCommunityTopicArgs = {
  accessToken: string;
  version?: string;
  topicId: number;
};

export const getCommunityTopic = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
}: GetCommunityTopicArgs): Promise<Result<GetCommunityTopicResponse>> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.get(
    `/${version}/community-topics/${topicId}`,
    GetCommunityTopicResponseScheme,
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UseGetCommunityTopicParams = GetCommunityTopicArgs & {
  config?: UseQueryOptions<Result<GetCommunityTopicResponse>>;
};

export const useGetCommunityTopic = ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
  config,
}: UseGetCommunityTopicParams) => {
  return useQuery<Result<GetCommunityTopicResponse>>({
    ...config,
    queryKey: ['getCommunityTopic', topicId],
    async queryFn() {
      return getCommunityTopic({accessToken, version, topicId});
    },
  });
};

export const followCommunityTopic = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
}: {
  accessToken: string;
  version?: string;
  topicId: number;
}): Promise<Result<UpdateFollowingCommunityTopicResponse>> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.post(
    `/${version}/community-topics/${topicId}/like`,
    UpdateFollowingCommunityTopicResponseSchema,
    {},
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const unFollowCommunityTopic = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
}: {
  accessToken: string;
  version?: string;
  topicId: number;
}): Promise<Result<UpdateFollowingCommunityTopicResponse>> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.delete(
    `/${version}/community-topics/${topicId}/like`,
    UpdateFollowingCommunityTopicResponseSchema,
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type PostAlertCommunityTopicArgs = {
  accessToken: string;
  version?: string;
  topicId: number;
};

export const postAlertCommunityTopic = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
}: PostAlertCommunityTopicArgs): Promise<
  Result<PostAlertCommunityTopicResponse>
> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.post(
    `/${version}/community-topics/${topicId}/alert`,
    PostAlertCommunityTopicResponseScheme,
    {},
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type GetDoubtReasonListForCommunityTopicArgs = {
  accessToken: string;
  version?: string;
  topicId: number;
};

export const getDoubtReasonListForCommunityTopic = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
}: GetDoubtReasonListForCommunityTopicArgs): Promise<
  Result<GetCommunityDoubtReasonResponse>
> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.get(
    `/${version}/community-topics/${topicId}/doubt-reasons`,
    GetCommunityDoubtReasonResponseSchema,
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useGetDoubtReasonListForCommunityTopic = ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
}: GetDoubtReasonListForCommunityTopicArgs) => {
  return useQuery<Result<GetCommunityDoubtReasonResponse>>({
    queryKey: ['getDoubtReasonListForCommunityTopic', topicId],
    async queryFn() {
      return getDoubtReasonListForCommunityTopic({
        accessToken,
        version,
        topicId,
      });
    },
  });
};

type GetCommunityCommentsArgs = {
  accessToken: string;
  version?: string;
  topicId: number;
  cursor?: number;
  limit?: number;
  backward?: boolean;
  desc?: boolean;
  initial?: boolean;
  keyword?: string;
};

export const getCommunityComments = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
  cursor,
  limit,
  backward,
  desc,
  initial,
  keyword,
}: GetCommunityCommentsArgs): Promise<Result<GetCommunityCommentsResponse>> => {
  defaultClient.setToken(accessToken);
  const params = filterExistParams({
    cursor,
    limit,
    backward,
    desc,
    initial,
    keyword,
  });
  const path = createPathWithQuery(
    `/${version}/community-topics/${topicId}/comments`,
    params,
  );
  const result = await defaultClient.get(
    path,
    GetCommunityCommentsResponseSchema,
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UseGetCommunityCommentsParams = GetCommunityCommentsArgs & {
  config?: UseQueryOptions<Result<GetCommunityCommentsResponse>>;
};

export const useGetCommunityCommentList = ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
  cursor,
  limit,
  backward,
  desc,
  initial,
  keyword,
  config,
}: UseGetCommunityCommentsParams) => {
  return useQuery<Result<GetCommunityCommentsResponse>>({
    ...config,
    queryKey: [
      'getCommunityCommentList',
      topicId,
      cursor,
      limit,
      backward,
      desc,
      initial,
      keyword,
    ],
    async queryFn() {
      return getCommunityComments({
        accessToken,
        version,
        topicId,
        cursor,
        limit,
        backward,
        desc,
        initial,
        keyword,
      });
    },
  });
};

type PostCommunityCommentArgs = {
  accessToken: string;
  version?: string;
  topicId: number;
  nickname: string;
  body: string;
};

export const postCommunityComment = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
  nickname,
  body,
}: PostCommunityCommentArgs): Promise<Result<PostCommunityCommentResponse>> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.post(
    `/${version}/community-topics/${topicId}/comments`,
    PostCommunityCommentResponseSchema,
    {nickname, body},
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UsePostCommunityCommentParams = {
  accessToken: string;
  version?: string;
  onMutateFnc?: () => void;
  onSuccessFnc: () => void;
  onErrorFnc: (error: AppError) => void;
  onSettledFnc?: () => void;
};

export const usePostCommunityComment = ({
  accessToken,
  version = DEFAULT_API_VERSION,
  onMutateFnc,
  onSuccessFnc,
  onErrorFnc,
  onSettledFnc,
}: UsePostCommunityCommentParams) => {
  return useMutation({
    async mutationFn({
      topicId,
      nickname,
      body,
    }: Omit<PostCommunityCommentArgs, 'accessToken'>) {
      return postCommunityComment({
        accessToken,
        version,
        topicId,
        nickname,
        body,
      });
    },
    onMutate() {
      if (onMutateFnc) {
        onMutateFnc();
      }
    },
    onSuccess() {
      onSuccessFnc();
    },
    onError(error: AppError) {
      onErrorFnc(error);
    },
    onSettled() {
      if (onSettledFnc) {
        onSettledFnc();
      }
    },
  });
};

type GetCommunityCommentArgs = {
  accessToken: string;
  version?: string;
  topicId: number;
  seqNumber: number;
};

export const getCommunityComment = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
}: GetCommunityCommentArgs): Promise<Result<GetCommunityCommentResponse>> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.get(
    `/${version}/community-topics/${topicId}/comments/${seqNumber}`,
    GetCommunityCommentResponseSchema,
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UseGetCommunityCommentParams = GetCommunityCommentArgs & {
  config?: UseQueryOptions<Result<GetCommunityCommentResponse>>;
};

export const useGetCommunityComment = ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
  config,
}: UseGetCommunityCommentParams) => {
  return useQuery<Result<GetCommunityCommentResponse>>({
    ...config,
    queryKey: ['getCommunityComment', topicId, seqNumber],
    async queryFn() {
      return getCommunityComment({accessToken, version, topicId, seqNumber});
    },
  });
};

type UpdateLikedCommunityCommentArgs = {
  accessToken: string;
  version?: string;
  topicId: number;
  seqNumber: number;
};

export const postLikedCommunityComment = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
}: UpdateLikedCommunityCommentArgs): Promise<
  Result<UpdateLikedCommunityCommentResponse>
> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.post(
    `/${version}/community-topics/${topicId}/comments/${seqNumber}/like`,
    UpdateLikedCommunityCommentResponseSchema,
    {},
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const deleteLikedCommunityComment = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
}: UpdateLikedCommunityCommentArgs): Promise<
  Result<UpdateLikedCommunityCommentResponse>
> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.delete(
    `/${version}/community-topics/${topicId}/comments/${seqNumber}/like`,
    UpdateLikedCommunityCommentResponseSchema,
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type PostAlertCommunityCommentArgs = {
  accessToken: string;
  version?: string;
  topicId: number;
  seqNumber: number;
};

export const postAlertCommunityComment = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
}: PostAlertCommunityCommentArgs): Promise<
  Result<PostAlertCommunityCommentResponse>
> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.post(
    `/${version}/community-topics/${topicId}/comments/${seqNumber}/alert`,
    PostAlertCommunityCommentResponseSchema,
    {},
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UsePostAlertCommunityCommentParams = PostAlertCommunityCommentArgs & {
  onMutateFnc?: () => void;
  onSuccessFnc: () => void;
  onErrorFnc: (error: AppError) => void;
  onSettledFnc?: () => void;
};

export const usePostAlertCommunityComment = ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
  onMutateFnc,
  onSuccessFnc,
  onErrorFnc,
  onSettledFnc,
}: UsePostAlertCommunityCommentParams) => {
  return useMutation({
    async mutationFn() {
      return postAlertCommunityComment({
        accessToken,
        version,
        topicId,
        seqNumber,
      });
    },
    onMutate() {
      if (onMutateFnc) {
        onMutateFnc();
      }
    },
    onSuccess() {
      onSuccessFnc();
    },
    onError(error: AppError) {
      onErrorFnc(error);
    },
    onSettled() {
      if (onSettledFnc) {
        onSettledFnc();
      }
    },
  });
};

type PostDoubtCommunityCommentArgs = {
  accessToken: string;
  version?: string;
  topicId: number;
  seqNumber: number;
  doubtReasonId: number;
  detail: string;
};

export const postDoubtCommunityComment = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
  doubtReasonId,
  detail,
}: PostDoubtCommunityCommentArgs): Promise<
  Result<PostDoubtCommunityCommentResponse>
> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.post(
    `/${version}/community-topics/${topicId}/comments/${seqNumber}/doubt`,
    PostDoubtCommunityCommentResponseSchema,
    {doubtReasonId, detail},
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UsePostDoubtCommunityCommentParams = Omit<
  PostDoubtCommunityCommentArgs,
  'doubtReasonId' | 'detail'
> & {
  onMutateFnc?: () => void;
  onSuccessFnc: () => void;
  onErrorFnc: (error: AppError) => void;
  onSettledFnc?: () => void;
};

export const usePostDoubtCommunityComment = ({
  accessToken,
  version = DEFAULT_API_VERSION,
  topicId,
  seqNumber,
  onMutateFnc,
  onSuccessFnc,
  onErrorFnc,
  onSettledFnc,
}: UsePostDoubtCommunityCommentParams) => {
  return useMutation({
    async mutationFn({
      doubtReasonId,
      detail,
    }: {
      doubtReasonId: number;
      detail: string;
    }) {
      return postDoubtCommunityComment({
        accessToken,
        version,
        topicId,
        seqNumber,
        doubtReasonId,
        detail,
      });
    },
    onMutate() {
      if (onMutateFnc) {
        onMutateFnc();
      }
    },
    onSuccess() {
      onSuccessFnc();
    },
    onError(error: AppError) {
      onErrorFnc(error);
    },
    onSettled() {
      if (onSettledFnc) {
        onSettledFnc();
      }
    },
  });
};

type GetCommunityTagListArgs = {
  accessToken: string;
  version?: string;
  isPopular: boolean;
};

export const getCommunityTagList = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
  isPopular = false,
}: GetCommunityTagListArgs): Promise<
  Result<GetCommunityTagListResponseType>
> => {
  defaultClient.setToken(accessToken);
  const path = createPathWithQuery(`/${version}/community-tags`, {isPopular});

  const result = await defaultClient.get(
    path,
    GetCommunityTagListResponseScheme,
  );

  if (!result.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UseGetCommunityTagListParams = GetCommunityTagListArgs & {
  config?: UseQueryOptions<Result<GetCommunityTagListResponseType>>;
};

export const useGetCommunityTagList = ({
  accessToken,
  version = DEFAULT_API_VERSION,
  isPopular,
  config,
}: UseGetCommunityTagListParams) => {
  return useQuery<Result<GetCommunityTagListResponseType>>({
    ...config,
    queryKey: ['getCommunityTagList', isPopular],
    async queryFn() {
      return getCommunityTagList({accessToken, version, isPopular});
    },
  });
};
